import { Route, Routes } from 'react-router-dom';

import AddLink from './components/AddLink';
import EditLink from './components/EditLink';
import Inventory from './components/Inventory';
import Logs from './components/Logs';
import Redir from './components/Redir';
import Sda from './components/Sda';
import Stats from './components/Stats';

const App = () => {
  return (
    <Routes>
      <Route path="/link" element={<AddLink />} />
      <Route path="/edit-link" element={<EditLink />} />
      <Route path="/sda" element={<Sda />} />
      <Route path="/redir" element={<Redir />} />
      <Route path="/logs" element={<Logs />} />
      <Route path="/stats" element={<Stats />} />
      <Route path="/inventory" element={<Inventory />} />
    </Routes>
  );
};

export default App;
